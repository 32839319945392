<template>
  <div>
    <el-dialog :close-on-click-modal="false" destroy-on-close title="打印设置" v-model="dialogFormVisibleManager"  class="button_bg">
      <el-form ref="dataForm" label-position="right" label-width="110px">
        <el-form-item label="所属期:" v-if="datePeriod">
            <div>
              <el-date-picker
                v-model="startPeriod"
                type="month"
                placeholder="选择月"
                size="small"
                value-format="YYYYMM">
              </el-date-picker> --
              <el-date-picker
                v-model="endPeriod"
                type="month"
                placeholder="选择月"
                size="small"
                value-format="YYYYMM">
              </el-date-picker>
            </div>
        </el-form-item>
        <el-form-item label-width="42px" v-if="this.name == 'book_subject_balance'">
            是否只显示有金额科目 ：
          <el-switch v-model="not0" size="large" /> 
        </el-form-item>
        <el-form-item label="选择级别 ：" :label-width="formLabelWidth" v-if="this.name == 'book_subject_balance'">
          <el-input placeholder="1" style="width: 90px;" class="filter-item" size="small" :disabled="true" />
          <span>-</span>
          <el-input placeholder="请输入级别" v-model.number="temp.level" style="width: 90px;margin-right:10px" class="filter-item" size="small" />
        </el-form-item>
        <el-form-item label="科目:" v-if="status.subje">
          <subject-list-all v-model:subjectId="listQuery1.subjectId" clear></subject-list-all>
          <span style="color:red;font-size: 20px;vertical-align: middle;">*</span><span style="color:red">不选默认打印全部</span>
        </el-form-item>
        <el-form-item label="纸张类型:" size="small" v-if="status.zzlx">
          <div>
            <el-radio-group v-model="temp.paperType">
              <!-- <subject-list subjectName="测试科目" v-model:subjectId="listQuery.id" v-model:fzhsItemId="listQuery.id2" :codes="['1001','1002']"></subject-list> -->
              <el-radio label="A4-S" ref="A4-S" v-if="status.zzlx4S" border>A4纸竖向</el-radio>
              <el-radio label="A4-H" ref="A4-H" border v-if="status.zzlx4H">A4纸横向</el-radio>
              <el-radio label="A5-H" ref="A5H" border v-if="status.zzlx5H">凭证纸</el-radio>
              <el-radio label="" border v-if="status.zzlxk">默认纸张</el-radio>
            </el-radio-group>
          </div>
        </el-form-item>
        <el-form-item label="科目选择：" class="item_row" v-if="status.kmxz">
          <el-radio-group v-model="temp.level">
            <el-radio :label="0">默认</el-radio>
            <el-radio :label="1">一级</el-radio>
            <el-radio :label="2">二级</el-radio>
            <el-radio :label="3">三级</el-radio>
            <el-radio :label="4">四级</el-radio>
            <el-radio :label="5">五级</el-radio>
            <el-radio :label="100">最下级科目</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="规则：" class="item_row" v-if="status.gz">
          <el-radio-group v-model="temp.merge">
            <el-radio :label="0">默认</el-radio>
            <el-radio :label="1">合并</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="快捷选择:" v-if="status.kjxz">
            <div>
              <el-radio-group size="small" @change="kjChange" v-model="kj">
                <el-radio :label="1" border>凭证纸(竖)</el-radio>
                <el-radio :label="2" border>针式打印机</el-radio>
                <el-radio :label="3" border>凭证纸(横)</el-radio>
                <el-radio :label="4" border>默认</el-radio>
              </el-radio-group>
            </div>
        </el-form-item>

        <el-form-item label="选项:" v-if="status.xx">
            <div>
              <el-radio-group v-model="temp.wb">
                <el-radio :label="0">正常</el-radio>
                <el-radio :label="1">外币</el-radio>
                <el-radio :label="2">数量金额</el-radio>
              </el-radio-group>
            </div>
        </el-form-item>

        <el-form-item label="字体：" class="item_row" v-if="status.zt">
          <el-radio-group v-model="temp.ttf">
            <el-radio :label="1">宋体</el-radio>
            <el-radio :label="2">微软雅黑</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="显示条数：" class="item_row" v-if="status.xsts">
          <el-input v-model.number="temp.num" placeholder="请输入内容" size="small" style="width:80%"></el-input>
        </el-form-item>

        <el-form-item label="字体大小" class="item_row" v-if="status.ztdx">
          <el-input type="number" v-model.number="temp.ttfSize" placeholder="请输入内容" size="small" style="width:80%"></el-input>
        </el-form-item>

        <!-- <div v-if="status.zzlx5H"> -->
          <el-form-item label="左偏移(mm)：" class="item_row" v-if="status.zpy">
            <el-input type="number" v-model.number="temp.left" placeholder="请输入内容" size="small" style="width:80%"></el-input>
          </el-form-item>
          <el-form-item label="右偏移(mm)：" class="item_row" v-if="status.ypy">
            <el-input type="number" v-model.number="temp.right" placeholder="请输入内容" size="small" style="width:80%"></el-input>
          </el-form-item>
        <!-- </div> -->

        <div v-if="status.tbwh">
          <el-form-item label="上偏移(mm)：" class="item_row">
          <el-input type="number" v-model.number="temp.top" placeholder="请输入内容" size="small" style="width:80%"></el-input>
          </el-form-item>
          <el-form-item label="下偏移(mm)：" class="item_row">
          <el-input type="number" v-model.number="temp.bottom" placeholder="请输入内容" size="small" style="width:80%"></el-input>
          </el-form-item>
          <el-form-item label="宽度(mm)：" class="item_row">
            <el-input type="number" v-model.number="temp.wide" placeholder="请输入内容" size="small" style="width:80%"></el-input>
          </el-form-item>
          <el-form-item label="高度(mm)：" class="item_row">
          <el-input type="number" v-model.number="temp.long" placeholder="请输入内容" size="small" style="width:80%"></el-input>
          </el-form-item>
        </div>

        <div>
          <el-form-item label="选项：" class="item_row" v-if="status.xuanxiang">
            <el-checkbox-group v-model="checkList">
              <el-checkbox label="屏蔽参考信息"></el-checkbox>
              <el-checkbox label="屏蔽业务日期2"></el-checkbox>
              <el-checkbox label="屏蔽序号"></el-checkbox>
              <el-checkbox label="屏蔽制作时间"></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </div>


      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogFormVisibleManager = false" size="small">取消</el-button>
          <el-button type="primary" @click="print()" size="small">确定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { printSetOne,accountBook } from "@/api/printSet";
import { inventoryDetail ,InOutInventoryDetail,printFixedAssets } from '@/api/printSet'
import { is } from "@babel/types";

import { number } from 'echarts';
export default {
  props: {
    name: {
      type: String,
    },
    listQuery1: {
      type: Object,
    },
    DY: {
      type:Number,
      default:0
    },
    optionType: {
      type:String,
      default: '1'
    },
    datePeriod:{
      type:Boolean,
      default:false
    }
  },
  data () {
    return {
      checkList: [],
      kj:"",
      temp: {
        level:0,
        merge:0,
        type: "pdf",
        comId: 0,
        paperType: "A4-S",
        long: 0,//pdf尺寸
        wide: 0,//pdf尺寸
        num: 0,//生成一页条数
        left: 0,//pdf左偏移
        ttfSize: 0,
        top: 0,//偏移
        right: 0,//偏移
        bottom:0,//偏移
        ttf:1,
        wb:0,
      },
      status:{
        subje:false,
        kmxz:false,
        gz:false,
        type:false,
        zzlx: false,//xxx
        kjxz: false,
        xx: false,
        zt: false,
        xsts: false,
        ztdx: false,
        zpy: false,
        ypy: false,
        xuanxiang: false,
        tbwh: false,
        sublall:false
      },
      dialogFormVisibleManager: false,
      startPeriod:'',
      endPeriod:'',
      not0:false,
      comIds:[],
      period:'',
      ids:[],
      optionType:''
    };
  },
  methods: {
    init(comIds,period,ids,wbStatatus){
      if(comIds){
        this.comIds = comIds
      }
      if(period){
        this.period = period
      }
      if(ids){
        this.ids = ids
      }
      if(wbStatatus == 'wb'){
        this.optionType = '1'
      }else if(wbStatatus == 'sl'){
        this.optionType = '2'
      }else{
        this.optionType = ''
      }
      this.initTemp()
      this.dialogFormVisibleManager = true
      printSetOne({type:'pdf', bookName:this.name}).then(res=>{
        this.temp = res.data.data.info?res.data.data.info:this.temp
        this.temp.type = "pdf"
      })
    },
    initTemp(){
      if(this.name == "book_voucher"){
        this.status.zzlx = true
        this.status.zzlx4S =true
        this.status.kjxz = true
        this.status.xx = true
        this.status.zt = true
        this.status.xsts = true
        this.status.ztdx = true
        this.status.zpy = true
        this.status.ypy = true
        this.status.xuanxiang = true
      }else if(this.name == "book_subject_balance") {
        this.status.zzlx4H = true
        this.status.zzlx = true
        this.status.zt = true
        this.status.zpy = true
        this.status.ypy = true
        this.status.zzlx5H = true
      }else if(this.name == "book_detail_account") {
          this.status.kmxz = true
          this.status.gz = true
          this.status.zzlx = true
          this.status.sublall = true
          this.status.zzlx4H = true
          this.status.zzlx4S = true
          this.status.subje = true
      }
      else if(this.name == "book_all_voucher") {
          this.status.zzlx = true
          this.status.zt = true
          this.status.zpy = true
          this.status.ypy = true
          this.status.zzlx5H = true
          this.status.zzlx4H = true
          this.status.zzlx4S = true
      }else if(this.name == "book_all_account") {
          this.status.zzlx = true
          this.status.zzlx4H = true
          this.status.zzlx4S = true
      }else if(this.name == "book_diary_account") {
          this.status.zzlx = true
          this.status.zzlx4H = true
          this.status.zzlx4S = true
          this.status.subje = true
      }else if(this.name == "inventoryPeriod") {
          this.status.zzlx = true
          this.status.zzlx4H = true
          this.status.zt = true
          this.status.zpy = true
          this.status.ypy = true
          this.status.zzlxk = true
      }else if(this.name == "inventoryDetail") {
          this.status.zzlx = true
          this.status.zzlx4H = true
          this.status.zt = true
          this.status.zpy = true
          this.status.ypy = true
      }else if(this.name == "assets") {
          this.status.zzlx = true
          this.status.zzlx4H = true
          this.status.zt = true
          this.status.zpy = true
          this.status.ypy = true
      }
    },
    // 打印
    print(){
      let params = Object.assign({}, this.temp)
      params.query = this.listQuery1
      params.query.name = ''

      if(this.comIds.length != 0){
        params.comIds = this.comIds
      }else{
        params.comIds = [this.listQuery1.comId]
      }
      if(this.period){
        params.period = this.period
      }
      if(this.ids){
        params.ids = this.ids
      }
      params.bookNames = [this.name]
      params.startPeriod = this.startPeriod
      params.endPeriod = this.endPeriod
      params.not0 = this.not0
      
      if(this.listQuery1.endTime < this.listQuery1.beginTime) {
        this.$message({
          showClose: true,
          message: '请查看日期起止是否填写正确',
          type: 'warning'
        });
        return
      }
      if(params.comIds.length > 1){
        params.zip = 1
      }else{
        params.zip = 0
      }
      if(this.name  == "inventoryPeriod"){
        params.paperType = 'A4-H'
        inventoryDetail(params).then(res => {
          window.open(res.data.data.url); 
          this.dialogFormVisibleManager = false
        })
        return
      }
      // 出入库明细
      if(this.name == "inventoryDetail"){
        if(!params.paperType){
          params.paperType = 'A4-H'
        }
        InOutInventoryDetail(params).then(res => {
          window.open(res.data.data.url); 
          this.dialogFormVisibleManager = false
        })
        return
      }
      if(this.name  == "assets"){
        params.paperType = 'A4-H'
        printFixedAssets(params).then(res => {
          window.open(res.data.data.url); 
          this.dialogFormVisibleManager = false
        })
        return
      }
      if(this.name == 'book_subject_balance'){
        params.optionType = this.optionType
      }
      accountBook(params).then(res=>{
        if (res.data.msg == "success") {
          window.open(res.data.data.url); 
          this.dialogFormVisibleManager = false
        }
      })
    }
  }
};
</script>
<style lang="scss" scoped>

</style>


